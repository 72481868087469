<template>
  <v-card
    id="tech-user-form"
    flat
  >
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-form
      ref="techUserForm"
      v-model="valid"
      lazy-validation
    >
      <v-card-title>API User Form</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="techUserForm.username"
          label="Username"
          required
          :disabled="usernameDisabled"
          :rules="name_rules"
          :counter="255"
        />

        <v-text-field
          v-model="techUserForm.email"
          label="Email"
          required
          :rules="email_rules"
          :counter="255"
        />

        <v-select
          v-model="techUserForm.policy_id"
          label="Policy"
          :items="tech_user.tPolicies"
          item-text="policy_name"
          item-value="policy_id"
          :search-input.sync="searchPolicy"
          :rules="policy_id_rules"
          outlined
          required
        >
          <template
            slot="selection"
            slot-scope="data"
          >
            {{ data.item.policy_id }} - {{ data.item.policy_name }}
          </template>
          <template v-slot:[`item`]="{ item }">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 30em"
            >
              {{ item.policy_id }} - {{ item.policy_name }}
            </span>
          </template>
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          class="mr-4"
          @click="clear"
        >
          Clear
        </v-btn>
        <v-spacer />
        <v-btn
          dark
          color="color_green"
          class="mr-4"
          :disabled="checkForm()"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  required,
  manageLenghtError,
  getRegExp,
  email_regexp
} from '@/formRules.js';

export default {
  data() {
    return {
      overlay: false,
      valid: true,
      searchPolicy: '',
      techUserForm: {
        username: '',
        email: '',
        policy_id: null,
        customer_id: ''
      },
      usernameDisabled: false,
      name_rules: [
        ...required,
        ...getRegExp('space_not_allowed'),
        ...getRegExp('no_uppercase'),
        ...manageLenghtError(255)
      ],
      email_rules: [...required, ...email_regexp],
      policy_id_rules: [...required]
    };
  },

  computed: {
    ...mapState(['tech_user'])
  },

  created() {
    this.storePoliciesTU();
    this.techUserForm.customer_id = this.$store.state.customerCRMID;
    this.techUserForm.customer_id = this.$store.state.crm_id;

    if (this.tech_user.isModifingTechUser == true) {
      this.usernameDisabled = true;
      this.techUserForm = this.$store.state.customerCRMID;
    }
  },

  methods: {
    ...mapActions([
      'storeNewTechUser',
      'storeUpdatedTechUser',
      'storeToastMessage',
      'storePoliciesTU'
    ]),
    checkForm() {
      return !(this.valid &&
        this.techUserForm.email !== '' &&
        this.techUserForm.username !== '' &&
        this.techUserForm.policy_id !== null);
    },
    async submit() {
      this.overlay = true;

      if (this.tech_user.isModifingTechUser == true) {
        this.storeUpdatedTechUser(this.techUserForm);
      } else {
        if (!this.checkForm() && this.valid) {
          await this.storeNewTechUser(this.techUserForm);
        } else {
          this.storeToastMessage({
            text: 'Please set required fields',
            type: 'warning'
          });
        }
      }

      this.clear();
      this.overlay = false;
    },
    clear() {
      this.$emit('closeDialog');
    }
  }
};
</script>
